import React, { useState } from 'react'
import { sendInitMessage } from '../../api/Api'
import { message } from 'react-message-popup'
import styles from './styles.module.scss'
import plusIco from '../../icon/plus.svg'
import showIcon from '../../icon/showIcon.svg'
import { useFormik } from 'formik'
import { useGlobalState } from '../../store/GlobalState'

export const InitialMessageBlock = () => {
  const [show, setShow] = useState(true)
  const [, setUpdateMessageBox] = useGlobalState('updateMessageBox')

  const handleInitMessage = async (userId: number, msg: string) => {
    const resp = await sendInitMessage({
      recipient_id: userId,
      message: msg,
    })
    if (resp?.status === 'error') {
      formik.resetForm()
      if (resp.message) return message.warning(resp.message, 4000)
      return message.warning('Error!!!', 4000)
    }
    setUpdateMessageBox(true)
    formik.resetForm()
    setShow(true)
  }

  const formik = useFormik({
    initialValues: {
      userId: '',
      message: '',
    },
    onSubmit: values => {
      const userId = Number(values.userId)
      handleInitMessage(userId, values.message)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className=" mb-3">
      <div className="d-flex align-items-center input-group-append ">
        <button
          className={`btn ms-auto  me-2 mb-2 ${styles.showButton}`}
          type="button"
          onClick={() => setShow(prev => !prev)}>
          <img src={show ? plusIco : showIcon} alt="+" />
        </button>
      </div>
      {!show ? (
        <>
          <div className="input-group mb-2">
            <input
              id="userId"
              name="userId"
              type="text"
              value={formik.values.userId}
              onChange={formik.handleChange}
              className={`form-control h-auto me-1 rounded-0 border-0 py-2 bg-light opacity-50 ${styles.input}`}
              placeholder="User id:"
              aria-describedby="basic-addon2"
            />
          </div>
          <div className="input-group mb-3">
            <input
              id="message"
              name="message"
              type="text"
              required={true}
              value={formik.values.message}
              onChange={formik.handleChange}
              className={`form-control h-auto me-1 rounded-0 border-0 py-2 bg-light opacity-50 ${styles.input}`}
              placeholder="first message"
              aria-describedby="basic-addon2"
            />
          </div>

          <div className="d-flex align-items-center input-group-append ">
            <button
              className={`btn ms-2 ${styles.sendBtn} ${styles.addNewChat}`}
              type="submit">
              Add
            </button>
          </div>
        </>
      ) : null}
    </form>
  )
}
