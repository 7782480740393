// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_messages__NHKvb {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.styles_emailClear__ctfkJ {
  --bs-text-opacity: 1;
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.styles_emailClear__ctfkJ:hover {
  color: #6c757d;
}

.styles_emailWrap__Ur5v2 {
  display: flex;
  align-items: center;
}
.styles_emailWrap__Ur5v2 img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 16px;
}

.styles_emailText__xkJ1k {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.styles_emailName__mDrcm {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.styles_emailStatus__4w-43 {
  font-size: 12px;
  font-weight: 500;
  color: #7987a1;
  margin-bottom: 0;
  white-space: nowrap;
}

.styles_time__s\\+tfv {
  font-size: 12px;
  font-weight: 500;
  color: #7987a1;
  margin-bottom: 0;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Navbar/Email/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,oBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AACF;AAAE;EACE,cAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".messages {\n  font-size: 14px;\n  margin-bottom: 0;\n  font-weight: 500;\n}\n\n.emailClear {\n  --bs-text-opacity: 1;\n  color: #6c757d;\n  font-size: 14px;\n  font-weight: 500;\n  text-decoration: none;\n  &:hover {\n    color: #6c757d;\n  }\n}\n\n.emailWrap {\n  display: flex;\n  align-items: center;\n  img {\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    margin-right: 16px;\n  }\n}\n\n.emailText {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 1;\n}\n\n.emailName {\n  margin-bottom: 0;\n  font-size: 13px;\n  font-weight: 500;\n  white-space: nowrap;\n}\n\n.emailStatus {\n  font-size: 12px;\n  font-weight: 500;\n  color: #7987a1;\n  margin-bottom: 0;\n  white-space: nowrap;\n}\n\n.time {\n  font-size: 12px;\n  font-weight: 500;\n  color: #7987a1;\n  margin-bottom: 0;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": `styles_messages__NHKvb`,
	"emailClear": `styles_emailClear__ctfkJ`,
	"emailWrap": `styles_emailWrap__Ur5v2`,
	"emailText": `styles_emailText__xkJ1k`,
	"emailName": `styles_emailName__mDrcm`,
	"emailStatus": `styles_emailStatus__4w-43`,
	"time": `styles_time__s+tfv`
};
export default ___CSS_LOADER_EXPORT___;
