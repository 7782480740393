import React from 'react'

import Sidebar from './Sidebar'

const Header: React.FC = () => {
  return (
    <>
      <Sidebar />
    </>
  )
}

export default Header
