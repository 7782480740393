import React from 'react';

interface InputFileProps {
    onChange: (file: File) => void,
    label?: string | JSX.Element,
    errorMessage?: string
    className?: string
}

const InputFile = ({onChange, label, errorMessage, className = ''}: InputFileProps) => {

    return (
        <div className={className}>
            <label className="form-label" htmlFor="customFile">{label}</label>
            <input type="file" className="form-control" id="customFile"
                   onChange={(event) => {
                       const file = event.target.files && event.target.files[0];
                       if (file) onChange(file);
                   }}/>
            {
                errorMessage ? (<p className="text-danger">{errorMessage}</p>) : ''
            }
        </div>
    );
}

export default InputFile;