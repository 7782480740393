import React from 'react'
import Container from '../../components/Container'
import Table from '../../components/Table'
import Tabs from '../../components/Tabs'

const connectData = {
  newMessage: [
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
  ],
  chats: [
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
  ],
  calls: [
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
    {
      date: new Date().toDateString(),
      lKll: 'Company',
      status: 'Read',
      name: 'Kaneki',
      phone: '8 800 555 35 35',
      email: 'example.com@gmail.com',
      shortMessage: 'Some message...',
    },
  ],
}

const Connect: React.FC = () => {
  const columns = {
    date: {
      title: 'Message Date',
    },
    lKll: {
      title: 'L Kll',
    },
    status: {
      title: 'Status',
    },
    name: {
      title: 'Name',
    },
    email: {
      title: 'E-mail',
    },
    phone: {
      title: 'Phone',
    },
    shortMessage: {
      title: 'Message',
    },
  }

  const tabs = [
    {
      key: 'newMessage',
      title: 'New message',
      content: <Table items={connectData.newMessage} columns={columns} />,
    },
    {
      key: 'chats',
      title: 'Chats',
      content: <Table items={connectData.chats} columns={columns} />,
    },
    {
      key: 'calls',
      title: 'Calls',
      content: <Table items={connectData.calls} columns={columns} />,
    },
  ]

  return (
    <Container title="Connect">
      <Tabs defaultActiveKey="newMessage" tabs={tabs} onClickTab={()  => false}/>
    </Container>
  )
}

export default Connect
