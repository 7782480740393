import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { sendSignup } from '../../api/Api'
import logo from '../../icon/logo.svg'
import styles from './styles.module.scss'

const Register: React.FC = () => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [icon, setIcon] = useState('')
  const [rules, setRules] = useState(true)

  const handleRegister = () => {
    const data = {
      name: username,
      email: email,
      password: password,
      icon: icon,
      rules: rules,
    }

    sendSignup(data)
      .then(res => {
        console.log('res: ', res.data)
        localStorage.setItem('token', res.data.access_token)
      })
      .catch(e => {
        console.log('e: ', e)
        console.log('data', data)
      })
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.pageWrapper}>
        <div className={styles.pageContent}>
          <Row className={styles.authPage}>
            <Col md="8" xl="6" className="mx-auto">
              <div className={styles.card}>
                <Row>
                  <Col md="4" className="pe-md-0">
                    <div className={styles.authSideWrapper} />
                  </Col>
                  <Col md="8" className="ps-md-0">
                    <div className="auth-form-wrapper px-4 py-5">
                      <img src={logo} alt="logo" width={315} />
                      <h5 className="text-muted fw-normal mb-4">
                        Create a free account.
                      </h5>
                      <form className="forms-sample">
                        <div className="mb-3">
                          <label htmlFor="userName" className="form-label">
                            User Name
                          </label>
                          <input
                            type="username"
                            className="form-control"
                            id="username"
                            placeholder="Username"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="userEmail" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="userEmail"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="userPassword" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="userPassword"
                            autoComplete="current-password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="icon" className="form-label">
                            Password
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="icon"
                            placeholder="Select a file"
                            value={icon}
                            onChange={e => setIcon(e.target.value)}
                          />
                        </div>
                        <div className="form-check mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="authCheck"
                            onChange={() => setRules(rules)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="authCheck">
                            Rules
                          </label>
                        </div>
                        <div
                          onClick={handleRegister}
                          className="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                          Sign up
                        </div>
                        <Link to={'/'} className="d-block mt-3 text-muted">
                          Not a user? Login
                        </Link>
                      </form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Register
