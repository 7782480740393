import { SET_TOGGLE_SIDEBAR } from './constants'

// export const getItems = () => {
//   return {
//     type: GET_TOGGLE_SIDEBAR,
//   }
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setToggleSidebar = (body: any) => ({
  type: SET_TOGGLE_SIDEBAR,
  payload: body,
})

