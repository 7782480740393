/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import React, { useEffect, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FeatherIcon from 'feather-icons-react'
import { Dropdown, Form, Table } from 'react-bootstrap'

import styles from './styles.module.scss'

export interface Item {
    [key: string]: React.ReactNode
}

interface Column {
    title: string
}

interface ColumnWithKey {
    title: string
    key: string
}

export interface Columns {
    [key: string]: Column
}

interface TableProps<I> {
    items: Item[] | I[]
    columns: Columns
    className?: string
    isHiddenSearch?: boolean
}

interface THProps {
    column: ColumnWithKey
    sortItems: (key: string, byIncreasing: boolean) => void
}

const MyTable = <T, >({items, columns, className = '', isHiddenSearch = true}: TableProps<T>) => {
    const key: string = '#'
    const itemsWithNumeration = items.length
        ? items?.map((item, index) => ({
            [key]: index + 1,
            ...item,
        }))
        : []
    const [searchedItems, setSearchedItems] = useState(itemsWithNumeration)
    const [sortedItems, setSortedItems] = useState(searchedItems)
    const [searchBy, setSearchBy] = useState<ColumnWithKey>()
    const [value, setValue] = useState<string>()

    const columnsWithKeys = Object.keys(columns).map(key => ({
        key,
        ...columns[key],
    }))

    const sortItems = (key: string, byIncreasing: boolean) => {
        setSortedItems(prevItems =>
            [...prevItems].sort((itemA: any, itemB: any) => {
                if (itemA[key] < itemB[key]) {
                    return byIncreasing ? 1 : -1
                } else if (itemA[key] > itemB[key]) {
                    return byIncreasing ? -1 : 1
                }
                return 0
            }),
        )
    }

    useEffect(() => {
        if (value) {
            if (searchBy) {
                setSearchedItems(
                    itemsWithNumeration.filter(item =>
                        item[searchBy.key]?.toString().includes(value),
                    ),
                )
            } else {
                setSearchedItems(
                    itemsWithNumeration.filter(item => {
                        for (const itemValue of Object.values(item)) {
                            if (itemValue?.toString().includes(value)) {
                                return true
                            }
                        }
                        return false
                    }),
                )
            }
        } else {
            setSearchedItems(itemsWithNumeration)
        }
    }, [value, searchBy])

    useEffect(() => {
        setSortedItems(searchedItems)
    }, [searchedItems])

    return (
        <div className={className}>
            {isHiddenSearch ? <div className={styles.actions}>
                    <div className={styles.left}></div>
                    <div className={styles.right}>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary">
                                {searchBy ? searchBy.title : 'Search by...'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setSearchBy(undefined)}>
                                    All
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setSearchBy({key: '#', title: '#'})}>
                                    #
                                </Dropdown.Item>
                                {columnsWithKeys.map(column => (
                                    <Dropdown.Item
                                        key={column.key}
                                        onClick={() => setSearchBy(column)}>
                                        {column.title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className={styles.search}>
                            <Form.Control
                                placeholder="Search..."
                                className={styles.input}
                                value={value}
                                onChange={event => setValue(event.target.value)}
                            />
                            <FeatherIcon size="14" icon="search" className={styles.icon}/>
                        </div>
                    </div>
                </div>
                : ''}
            <Table className={styles.table}>
                <thead>
                <tr className={styles.tr}>
                    <TH column={{key: '#', title: '#'}} sortItems={sortItems}/>
                    {columnsWithKeys.map(column => (
                        <TH key={column.key} column={column} sortItems={sortItems}/>
                    ))}
                </tr>
                </thead>
                <tbody>
                {sortedItems.map((item, index) => {
                    const columns: Array<any> = []
                    const specialFields = ['email', 'phone']
                    for (const key in item) {
                        if (specialFields.find(item => key === item)) {
                            columns.push({[key]: item[key]})
                        } else {
                            columns.push(item[key])
                        }
                    }
                    return (
                        <tr key={index} className={styles.tr}>
                            {columns.map((value, index) => (
                                <React.Fragment key={index}>
                                    <td key={index} className={styles.td}>
                                        {
                                            value?.email || value?.phone ?
                                                value?.email
                                                    ? <a className={styles.links}
                                                         href={`mailto:${value.email}`}>{value.email}</a>
                                                    : <a className={styles.links}
                                                         href={`tel:${value.phone}`}>{value.phone}</a> : value
                                        }
                                    </td>
                                </React.Fragment>
                            ))}
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    )
}

const TH: React.FC<THProps> = ({column, sortItems}) => {
    const [isByIncreasing, setIsByIncreasing] = useState(false)

    const handleClick = () => {
        sortItems(column.key, isByIncreasing)
        setIsByIncreasing(prevSortMode => !prevSortMode)
    }
    return (
        <th className={styles.th} onClick={handleClick}>
            {column.title}{' '}
            <FeatherIcon size="12" icon={`arrow-${isByIncreasing ? 'up' : 'down'}`}/>
        </th>
    )
}

export default MyTable
