import React from 'react'
import { Link } from 'react-router-dom'
import Email from './Email'
import Notice from './Notice'
import Profile from './Profile'
import SearchIcon from '../../../icon/search.svg'

import styles from './styles.module.scss'

const Header: React.FC = () => {
    return (
        <>
            <Link to={'/'} className={styles.sidebarToggler}>
                <i data-feather="menu"></i>
            </Link>
            <div className={styles.navbarContent}>
                <form className={styles.searchForm}>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputGroupText}>
                            <img src={SearchIcon} alt="search" width={20} height={20}/>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            id="navbarForm"
                            style={{
                                marginLeft: '-1px',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                border: 0,
                                marginTop: '3px',
                                position: 'relative',
                                flex: '1 1 auto',
                                width: '1%',
                                minWidth: 0,
                            }}
                            placeholder="Search here..."
                        />
                    </div>
                </form>

                {/*<Language />*/}
                {/*<App/>*/}
                <Email/>
                <Notice/>
                <Profile/>
            </div>
        </>
    )
}

export default Header
