import React, {SyntheticEvent} from 'react'
import {Spinner, Tab, Tabs} from 'react-bootstrap'

interface Tabs {
  key: string
  title: string
  content: React.ReactNode
}

interface Props {
  tabs: Tabs[]
  defaultActiveKey: string
  onClickTab: (name: string) => void
  isLoading?: boolean
}

const MyTabs: React.FC<Props> = ({ tabs, defaultActiveKey, onClickTab, isLoading = false }) => {
  return (
    <Tabs defaultActiveKey={defaultActiveKey} onSelect={(select) => select && onClickTab(select)}>
      {tabs.map(tab => (
          <Tab key={tab.key} eventKey={tab.key} title={tab.title}>
            {isLoading ? <Spinner className='d-flex m-auto mt-3' animation="border" variant="primary" /> : tab.content }
          </Tab>
      ))}
    </Tabs>
  )
}

export default MyTabs
