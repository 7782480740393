import React, { useMemo, useState } from 'react'
import Container from '../../components/Container'
import InputFile from "../../components/InputFile";
import * as XLSX from 'xlsx';
import Table, { Columns } from "../../components/Table";
import styles from './styles.module.scss'
import { postTestCompany } from "../../api/Api";

interface Column {
    [key: string]: string
}

const TestCompany: React.FC = () => {
    const [items, setItems] = useState([])
    const [file, setFile] = useState<FormData>()
    const [error, setError] = useState('')

    const handleChange = (file: File): void => {
        const promise = new Promise((resolve, reject) => {

            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)

            fileReader.onload = (e) => {
                const bufferArray = e.target?.result
                const wb = XLSX.read(bufferArray, {type: 'buffer'})
                const wsName = wb.SheetNames[0]
                const ws = wb.Sheets[wsName]
                const data = XLSX.utils.sheet_to_json(ws)
                resolve(data)
            }
            fileReader.onerror = ((error) => {
                setError(error.toString())
                reject(error)
            })
        })
        if (file.name.split('.')[1] === 'xlsx') {
            setError('')
            promise.then((data: any) => {
                if (data.length) {
                    const formDate = new FormData()
                    formDate.append("file", file)
                    setFile(formDate)
                    setItems(data)
                }
            })
        } else setError('No valid format!!!')
    }

    const columns = useMemo(() => {
        const keys: Columns = {}
        for (let i = 0; i < items.length; i++) {
            for (const key in items[i]) {
                if (!Object.prototype.hasOwnProperty.call(keys, key)) {
                    keys[key] = {
                        title: `${key}`
                    }
                }
            }
        }
        return keys
    }, [items])

    const data = useMemo(() => {
        return items.map((column) => {
            const col: Column = {}
            for (const key in columns) {
                col[key] = column[key] ?? '###'
            }
            return col
        })
    }, [items])

    const sendFile = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (file) {
            const resp = await postTestCompany(file)
            if (resp.data?.status === "error") {
                setError(resp.data.error.file[0])
                return
            }
            window.location.reload();
        }

    }
    return (
        <Container title="Test company">
            <form>
                <InputFile className={styles.selectFile} errorMessage={error} onChange={handleChange}/>
                <button disabled={!items.length} className="btn btn-primary mb-2" onClick={(event) => sendFile(event)}>
                    Send
                </button>
            </form>

            {items.length
                ? <Table isHiddenSearch={false} items={data} columns={columns} className={styles.testCompaniesTable}/>
                : <span className={styles.messageError}>Please select XLSM file!</span>}
        </Container>
    )
}

export default TestCompany
