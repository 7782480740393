// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__W0gSC {
  position: absolute;
  top: 90px;
  left: 280px;
}

.styles_title__S\\+qxS {
  font-size: 20px;
}

.styles_containerMax__drGpX {
  position: absolute;
  top: 90px;
  left: 95px;
  width: 92%;
  padding: 15px 25px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, margin 0.1s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,+CAAA;EACA,uDAAA;EACA,oDAAA;EACA,mDAAA;EAEA,iDAAA;AACF","sourcesContent":[".container {\n  position: absolute;\n  top: 90px;\n  left: 280px;\n}\n\n.title {\n  font-size: 20px;\n}\n\n.containerMax {\n  position: absolute;\n  top: 90px;\n  left: 95px;\n  width: 92%;\n  padding: 15px 25px;\n  border-radius: 8px;\n  background: #fff;\n  box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);\n  -webkit-box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);\n  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);\n  -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);\n  -webkit-transition: width 0.1s ease, margin 0.1s ease-out;\n  transition: width 0.1s ease, margin 0.1s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__W0gSC`,
	"title": `styles_title__S+qxS`,
	"containerMax": `styles_containerMax__drGpX`
};
export default ___CSS_LOADER_EXPORT___;
