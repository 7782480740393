import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'

import { Link, useNavigate } from 'react-router-dom'
import { useGlobalState } from '../../store/GlobalState'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import { InitialMessageBlock } from './InitialMessageBlock'
import { getChatsData } from '../../coomon/getChatsData'

export interface Interlocutor {
  name: string
  photo: string
}

export interface MessagesType {
  chat_id: number
  interlocutor: Interlocutor
  last_message: {
    message: string
    created_at: string
  }
  number_of_unread_messages: number
}

interface ChatUsersBoxProps {
  handleChatUsers: (data: MessagesType[]) => void
}

export const ChatUsersBox: React.FC<ChatUsersBoxProps> = ({
  handleChatUsers,
}) => {
  const [lastMessage] = useGlobalState('last_message')
  const [marksMessages, setMarksMessages] = useGlobalState('marksMessages')
  const [updateMessageBox, setUpdateMessageBox] =
    useGlobalState('updateMessageBox')
  const [user] = useGlobalState('user')
  const [mark, setMarks] = useState(true)

  const [chatsData, setChatsData] = useState<MessagesType[]>([])
  const [id, setId] = useState(0)
  const params = useParams()
  const navigate = useNavigate()

  const marks = (chatId: number, userId: number) => {
    const mrk = marksMessages.find(chat => chat.chatId === chatId)
    if (!mrk) return false
    return mrk.userId !== userId && mrk.mark
  }

  const handleClick = async (chatId: number) => {
    setId(chatId)
  }

  const fetchData = async (): Promise<MessagesType[]> => {
    const resp = await getChatsData()
    setChatsData(resp)
    handleChatUsers(resp)
    return resp
  }

  useEffect(() => {
    const chatId = Number(Object.values(params)[0]?.slice(3))
    fetchData()
    setId(chatId)
    setMarksMessages(marks => [
      ...marks.map(mark =>
        chatId === mark?.chatId
          ? {
              ...mark,
              mark: false,
            }
          : mark,
      ),
    ])
  }, [])

  useEffect(() => {
    if (updateMessageBox) {
      setId(0)
      const update = async () => {
        const resp = await fetchData()
        setId(resp[0].chat_id)
        navigate(`/chat/id=${resp[0].chat_id}`, { replace: true })
      }
      update()
      setUpdateMessageBox(false)
    }
  }, [updateMessageBox])

  useEffect(() => {
    const newLastMessage = chatsData.map(data =>
      data.chat_id === lastMessage.chatId
        ? {
            ...data,
            last_message: {
              message: lastMessage.message,
              created_at: lastMessage.createdAt,
            },
            number_of_unread_messages: lastMessage.numberOfUnreadMessages,
          }
        : data,
    )

    const messagesData = newLastMessage.sort((a, b) =>
      dayjs(a.last_message.created_at).isAfter(b.last_message.created_at)
        ? -1
        : 1,
    )
    setChatsData(messagesData)
    setMarks(marks(user.userId, lastMessage.chatId))
  }, [lastMessage])

  return (
    <div className="col-5 col-lg-4 px-0">
      <div className="bg-white">
        <InitialMessageBlock />
        <div className={styles.messagesBoxContainer}>
          <div className="list-group rounded-0">
            {chatsData.map(element => (
              <Link
                key={element.chat_id}
                to={`/chat/id=${element.chat_id}`}
                onClick={() => handleClick(element.chat_id)}
                className={`list-group-item list-group-item-action ${
                  styles.messagesBox
                } ${
                  id === element.chat_id
                    ? 'active text-white'
                    : 'item list-group-item-action'
                } rounded-0`}>
                <div className="media">
                  <img
                    src={element.interlocutor.photo}
                    alt="user"
                    width="50"
                    className="rounded-circle"
                  />
                  <div className="media-body ml-4">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h6 className="mb-0">{element.interlocutor.name}</h6>
                      {(element.number_of_unread_messages > 0 || mark) && (
                        <div className="pulse" />
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <span
                        className={`font-italic mb-0 me-3 ${styles.textSmall} ${styles.lastMessage}`}>
                        {element.last_message.message}
                      </span>
                      <small
                        className={`small font-weight-bold ${styles.lastMessage} ${styles.lastMessageDate}`}>
                        {dayjs(element.last_message.created_at).format(
                          'MM/MMM',
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
