import axios from 'axios'
import { URL } from '../constants'
import { refreshToken } from './Api'

const axiosInstance = axios.create({
  baseURL: URL,
})

axiosInstance.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token')

    config.headers = {
      'Access-Control-Allow-Origin': '*',
      Accept: '*/*',
      'Content-Type': 'application/json',
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    if (axios.isCancel(error)) {
      console.log('all request errors cancelled', error)
    } else {
      return Promise.reject(error)
    }
  },
)

axiosInstance.interceptors.response.use(
  config => {
    return config
  },
  async error => {
    if (error.response.status === 401) {
      try {
        const data = await refreshToken()
    const storage = localStorage.getItem('token')
      ? localStorage
      : sessionStorage
    storage.setItem('token', data.access_token)

        return axiosInstance.request(error.config)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
