import React from 'react'
import styles from './styles.module.scss'
import { Interlocutor } from './ChatUsersBox'
import dayjs from 'dayjs'

export interface InterlocutorType extends Interlocutor {
  id: number
}

export interface MessageType {
  created_at: string
  message: string
  user_id: number
  message_id: number
}

export interface ChatMessagesType {
  interlocutor: InterlocutorType
  messages: MessageType[]
}

const ChatMessages: React.FC<ChatMessagesType> = ({
  messages,
  interlocutor,
}) => {
  const newDay = (newDate: string, prevDate: string): JSX.Element => {
    const nD = new Date(newDate).toLocaleDateString()
    const pD = prevDate && new Date(prevDate).toLocaleDateString()

    if (nD !== pD) {
      return (
        <span className="d-flex justify-content-center opacity-50 m-5">
          {nD}
        </span>
      )
    } else return <></>
  }

  return (
    <>
      {messages.map((element, index) => (
        <div key={element.message_id}>
          {newDay(element.created_at, messages[index + 1]?.created_at)}
          <div
            className={`media mb-3 ${styles.chatMessageBox} ${
              element.user_id !== interlocutor.id ? 'ms-auto' : ''
            }`}>
            {element.user_id === interlocutor.id && (
              <span>
                <img
                  src={interlocutor.photo}
                  alt="user"
                  width="50"
                  className="rounded-circle"
                />
                <span className="ms-1">{interlocutor.name}</span>
              </span>
            )}

            <div className="media-body ml-3 mt-2">
              <div
                className={`${
                  element.user_id !== interlocutor.id
                    ? 'bg-primary'
                    : 'bg-light'
                } rounded py-2 px-3 mb-1`}>
                <p
                  className={`mb-0 ${
                    element.user_id !== interlocutor.id
                      ? 'text-white'
                      : 'text-muted'
                  }  
                  ${styles.textSmall} ${styles.chatMessage} `}>
                  {element.message}
                </p>
              </div>
              <span className="small opacity-50 ms-auto text-muted">
                {dayjs(element.created_at).format('HH:MM')}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default React.memo(ChatMessages)
