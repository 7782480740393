import { createGlobalState } from 'react-hooks-global-state'

interface State {
  user: {
    userId: number
  }
  last_message: {
    chatId: number
    message: string
    createdAt: string
    userId: number
    messageId: number
    numberOfUnreadMessages: number
  }
  marksMessages: {
    chatId: number
    mark: boolean
    userId: number
  }[]
  updateMessageBox: boolean
}

const initialState: State = {
  user: {
    userId: 0,
  },
  last_message: {
    chatId: 0,
    message: '',
    createdAt: '',
    userId: 0,
    messageId: 0,
    numberOfUnreadMessages: 0,
  },
  marksMessages: [],
  updateMessageBox: false,
}

export const { useGlobalState } = createGlobalState(initialState)
