import React, { useEffect, useState } from 'react'
import Container from '../../components/Container'
import styles from './styles.module.scss'
import ChatBox from './ChatBoxMessages'
import { ChatUsersBox, MessagesType } from './ChatUsersBox'
import { Route, Routes } from 'react-router-dom'
import Pusher from 'pusher-js'
import { useGlobalState } from '../../store/GlobalState'
import dayjs from 'dayjs'
import { MessageType } from './ChatMessages'

interface NewMessageProps extends MessageType {
    chat_id: number
}

const Chat: React.FC = () => {
    const [chatUsers, setChatUsers] = useState<MessagesType[]>([])
    const [, setLastMessage] = useGlobalState('last_message')
    const [marksMessages, setMarksMessages] = useGlobalState('marksMessages')
    const [user] = useGlobalState('user')
    const token = sessionStorage.getItem('token') || localStorage.getItem('token')

    useEffect(() => {
        let mounted = true
        const pusher = new Pusher('492804c31d0ccec97e54', {
            cluster: 'eu',
            authEndpoint: `https://api.cleanteam.app:8081/api/broadcasting/auth`,
            forceTLS: true,
            authTransport: 'ajax',
            auth: {
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${token}`,
                },
            },
        })
        if (mounted && chatUsers.length) {
            chatUsers.forEach(chat => {
                pusher
                    .subscribe(`private-chat.${chat.chat_id}`)
                    .bind('private-chat.message-sent', (data: NewMessageProps) => {
                        console.log('private-chat.message-sent', data)
                        if (marksMessages.length) {
                            setMarksMessages(marks => [
                                ...marks.map(mark =>
                                    data.chat_id === mark?.chatId
                                        ? {
                                            chatId: mark.chatId,
                                            userId: mark.userId,
                                            mark: true,
                                        }
                                        : {
                                            chatId: data.chat_id,
                                            userId: data.user_id,
                                            mark: true,
                                        },
                                ),
                            ])
                        } else
                            setMarksMessages([
                                {
                                    chatId: data.chat_id,
                                    userId: data.user_id,
                                    mark: true,
                                },
                            ])

                        setLastMessage(prev => ({
                            userId: data.user_id,
                            chatId: data.chat_id,
                            messageId: data.message_id,
                            message: data.message,
                            createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                            numberOfUnreadMessages:
                                data.user_id !== user.userId
                                    ? ++prev.numberOfUnreadMessages
                                    : 0,
                        }))
                    })
            })
        }
        return () => {
            chatUsers.forEach(chat => {
                pusher.unsubscribe(`private-chat.${chat.chat_id}`)
            })
            mounted = false
        }
    }, [chatUsers])

    return (
        <Container title="Chat">
            <div className={`row overflow-hidden ${styles.roundedLg}`}>
                <ChatUsersBox handleChatUsers={data => setChatUsers(data)}/>
                <Routes>
                    <Route path="/:id" element={<ChatBox/>}/>
                    <Route
                        path="/"
                        element={
                            <span className="d-flex m-auto w-auto opacity-50">
                Choose a chat please!
              </span>
                        }
                    />
                </Routes>
            </div>
        </Container>
    )
}
export default Chat
