import React from 'react'
import Container from '../../components/Container'
import Table from '../../components/Table'
import Tabs from '../../components/Tabs'

const usersData = {
  newUsers: [
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
  ],
  allUsers: [
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
  ],
  deletedUsers: [
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
    {
      name: 'Kaneki',
      email: 'example.com@gmail.com',
      phone: '8 800 555 35 35',
      address: 'Tokio 33333',
      city: 'Tokio',
      zip: '33333',
    },
  ],
}

const Users: React.FC = () => {
  const columns = {
    name: {
      title: 'Name',
    },
    email: {
      title: 'E-mail',
    },
    phone: {
      title: 'Phone',
    },
    address: {
      title: 'Address',
    },
    city: {
      title: 'City',
    },
    zip: {
      title: 'Zip Code',
    },
  }

  const tabs = [
    {
      key: 'newUsers',
      title: 'New Users',
      content: <Table items={usersData.newUsers} columns={columns} />,
    },
    {
      key: 'allUsers',
      title: 'All Users',
      content: <Table items={usersData.allUsers} columns={columns} />,
    },
    {
      key: 'deletedUsers',
      title: 'Deleted Users',
      content: <Table items={usersData.deletedUsers} columns={columns} />,
    },
  ]

  return (
    <Container title="Users">
      <Tabs defaultActiveKey="newUsers" tabs={tabs} onClickTab={() => false} />
    </Container>
  )
}

export default Users
