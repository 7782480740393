import React, { useEffect, useState } from 'react'
import Container from '../../components/Container'
import Table from '../../components/Table'
import Tabs from '../../components/Tabs'
import { getLeadsAll, getLeadsAvailable, getLeadsCompleted, getLeadsInWork, getLeadsNewToday, } from '../../api/Api'
import { FetchDataType } from '../../types/Leads'

interface NewTodayType {
    service: string
    type: string
    howFast: string
    address: string
    city: string
    zip: string
    name: string
    phone: string
    email: string
}

type Available = NewTodayType

interface InWorkType extends NewTodayType {
    bool: boolean
}

type CompletedType = InWorkType
type AllType = NewTodayType

interface DataType {
    allLeads: AllType[]
    newToday: NewTodayType[]
    available: Available[]
    inWork: InWorkType[]
    completed: CompletedType[]
}

const Leads: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<DataType>({
        allLeads: [],
        newToday: [],
        available: [],
        inWork: [],
        completed: [],
    })

    const columns = {
        service: {
            title: 'Service',
        },
        type: {
            title: 'Service type',
        },
        howFast: {
            title: 'How fast',
        },
        address: {
            title: 'Address',
        },
        city: {
            title: 'City',
        },
        zip: {
            title: 'Zip code',
        },
        name: {
            title: 'Name',
        },
        phone: {
            title: 'Phone',
        },
        email: {
            title: 'E-mail',
        },
        createdAt: {
            title: 'created',
        },
        createdFrom: {
            title: 'Created in',
        },
    }

    const onClickTab = (name: string) => {
        switch (name) {
            case 'allLeads':
                setIsLoading(true)
                getLeadsAll()
                    .then(res => {
                        setData({
                            ...data,
                            allLeads: res.data.map((item: FetchDataType) => ({
                                service: item.service_type[0],
                                type: item.type_of_location,
                                howFast: item.how_fast,
                                address: item.street,
                                city: item.city,
                                zip: item.zip_code,
                                name: item.name,
                                phone: item.phone,
                                email: item.email,
                                createdAt: item.created_at,
                                createdFrom: item.created_from
                            })),
                        })
                        setIsLoading(false)
                    })
                    .catch(() => setIsLoading(false))
                return

            case 'newToday':
                setIsLoading(true)
                getLeadsNewToday()
                    .then(res => {
                        setData({
                            ...data,
                            newToday: res.data.map((item: FetchDataType) => ({
                                service: item.service_type[0],
                                type: item.type_of_location,
                                howFast: item.how_fast,
                                address: item.street,
                                city: item.city,
                                zip: item.zip_code,
                                name: item.name,
                                phone: item.phone,
                                email: item.email,
                                createdAt: item.created_at,
                                createdFrom: item.created_from
                            })),
                        })
                        setIsLoading(false)
                    })
                    .catch(() => setIsLoading(false))
                return

            case 'inWork':
                setIsLoading(true)
                getLeadsInWork()
                    .then(res => {
                        setData({
                            ...data,
                            inWork: res.data.map((item: FetchDataType) => ({
                                service: item.service_type[0],
                                type: item.type_of_location,
                                howFast: item.how_fast,
                                address: item.street,
                                city: item.city,
                                zip: item.zip_code,
                                name: item.name,
                                phone: item.phone,
                                email: item.email,
                                createdAt: item.created_at,
                                createdFrom: item.created_from
                            })),
                        })
                        setIsLoading(false)
                    })
                    .catch(() => setIsLoading(false))
                return

            case 'completed':
                setIsLoading(true)
                getLeadsCompleted()
                    .then(res => {
                        setData({
                            ...data,
                            completed: res.data.map((item: FetchDataType) => ({
                                service: item.service_type[0],
                                type: item.type_of_location,
                                howFast: item.how_fast,
                                address: item.street,
                                city: item.city,
                                zip: item.zip_code,
                                name: item.name,
                                phone: item.phone,
                                email: item.email,
                                createdAt: item.created_at,
                                createdFrom: item.created_from
                            })),
                        })
                        setIsLoading(false)
                    })
                    .catch(() => setIsLoading(false))
                return

            case 'available':
                setIsLoading(true)
                getLeadsAvailable()
                    .then(res => {
                        setData({
                            ...data,
                            available: res.data.map((item: FetchDataType) => ({
                                service: item.service_type[0],
                                type: item.type_of_location,
                                howFast: item.how_fast,
                                address: item.street,
                                city: item.city,
                                zip: item.zip_code,
                                name: item.name,
                                phone: item.phone,
                                email: item.email,
                                createdAt: item.created_at,
                                createdFrom: item.created_from
                            })),
                        })
                        setIsLoading(false)
                    })
                    .catch(() => setIsLoading(false))
                return
            default: {
                return () => false
            }
        }
    }

    const tabs = [
        {
            key: 'allLeads',
            title: 'All',
            content: <Table items={data.allLeads} columns={columns}/>,
        },
        {
            key: 'newToday',
            title: 'New Today',
            content: <Table items={data.newToday} columns={columns}/>,
        },
        {
            key: 'available',
            title: 'Available',
            content: <Table items={data?.available} columns={columns}/>,
        },
        {
            key: 'inWork',
            title: 'In Work',
            content: <Table items={data.inWork} columns={columns}/>,
        },
        {
            key: 'completed',
            title: 'Completed',
            content: <Table items={data.completed} columns={columns}/>,
        },
    ]

    useEffect(() => {
        onClickTab('allLeads')
    }, [])

    console.log('columns', columns)
    console.log('data.allLeads', data.allLeads)

    return (
        <Container title="Leads">
            <Tabs
                defaultActiveKey="allLeads"
                tabs={tabs}
                onClickTab={onClickTab}
                isLoading={isLoading}
            />
        </Container>
    )
}

export default Leads
