import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import avatar from '../../../../img/face.jpg'
import styles from './styles.module.scss'
import { authLogout } from '../../../../api/Api';

const Profile: React.FC = () => {
    const navigate = useNavigate()

    const logout = async () => {
        await authLogout()

        const storage = localStorage.getItem('token')
            ? localStorage
            : sessionStorage

        storage.removeItem('token')

        navigate('/')
    }

    return (
        <Dropdown autoClose="outside">
            <Dropdown.Toggle className="p-0 mx-1" variant="white" id="dropdown-basic">
                <img
                    src={avatar}
                    className={styles.avatar}
                    alt="avatar"
                    width={30}
                    height={30}
                />
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className={styles.profilePopup}>
                <div className="px-3 pb-2 d-flex justify-content-between border-bottom">
                    <p className={styles.messages}>6 New Notifications</p>
                    <Link to={'/'} className={styles.noticeClear}>
                        Clear all
                    </Link>
                </div>
                <Dropdown.Item
                    eventKey="1"
                    className="d-flex justify-content-center align-items-center pt-2 pb-2 flex-column">
                    <div className={styles.noticeWrap}>
                        <img
                            src={avatar}
                            className={styles.noticeWrapAvatar}
                            alt="avatar"
                            width={80}
                            height={80}
                        />
                        <div className={styles.noticeText}>
                            <div>
                                <p className={styles.noticeName}>Alex</p>
                                <p className={styles.noticeStatus}>admin</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={logout} className={styles.logOut}>
                        Log out
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Profile
