import { getChats } from '../api/Api'
import { MessagesType } from '../pages/Сhat/ChatUsersBox'
import dayjs from 'dayjs'

export const getChatsData = async (): Promise<MessagesType[]> => {
  const resp = await getChats()
  return resp.data.data.sort((a: MessagesType, b: MessagesType) =>
    dayjs(a.last_message.created_at).isAfter(b.last_message.created_at)
      ? -1
      : 1,
  )
}
