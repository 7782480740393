import { createStore } from 'redux'
import rootReducer from './rootReducer'

const store = createStore(rootReducer)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const globalDispatch = (action: any) => {
  store.dispatch(action)
}

export { store }
