import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import styles from "../login/styles.module.scss";
import { Col, Row } from "react-bootstrap";
import logo from "../../icon/logo.svg";
import { useLocation } from "react-router";
import { NewPassword } from "./NewPassword";
import { forgotPassword, getResetPassword } from "../../api/Api";
import { toast } from "react-toastify";

const PasswordRecovery = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [redirectOnResetPage, setRedirectOnResetPage] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const token = location.search.split('=')[1]

    const handlePasswordRecovery = async (email: string) => {
        setLoading(true)
        const res = await forgotPassword({email})
        setLoading(false)

        if (res?.status === 'error') {
            setError(res.error.email[0])
            toast.error(res.error.email[0])
        }

        if (res?.status === 'success') {
            toast.success(res.message)
            navigate('/')
        }
    }

    const formik = useFormik({
        initialValues: {
            userEmail: '',
        },
        onSubmit: ({userEmail}) => {
            handlePasswordRecovery(userEmail)
        },
    })

    useEffect(() => {
        if (token) {
            (async function () {
                const res = await getResetPassword(token as string)
                if (res.status === 'error') {
                    toast.error(res.message)
                    navigate('/')
                    return
                }
                setRedirectOnResetPage(true)
            })()
        }
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.pageWrapper}>
                <div className={styles.pageContent}>
                    <Row className={styles.authPage}>
                        <Col md="8" xl="6" className="mx-auto">
                            <div className={styles.card}>
                                <Row>
                                    <Col md="4" className="pe-md-0">
                                        <div className={styles.authSideWrapper}/>
                                    </Col>
                                    <Col md="8" className="ps-md-0">
                                        <div className="auth-form-wrapper px-4 py-5">
                                            <img src={logo} alt="logo" width={315}/>
                                            <h5 className="text-muted fw-normal mb-4">
                                                Account recovery.
                                            </h5>
                                            {
                                                redirectOnResetPage && token ?
                                                    <NewPassword token={token}/> :
                                                    <form className="forms-sample" onSubmit={formik.handleSubmit}
                                                          onChange={() => setError('')}>
                                                        <div className="mb-3">
                                                            <label htmlFor="userEmail" className="form-label">
                                                                Email address
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="userEmail"
                                                                name='userEmail'
                                                                placeholder="Email"
                                                                value={formik.values.userEmail}
                                                                onChange={formik.handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        {error ? <p className='text-danger'>{error}</p> : null}
                                                        <button
                                                            draggable={loading}
                                                            type='submit'
                                                            disabled={!!error}
                                                            className="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                                                            Send
                                                        </button>
                                                        <Link
                                                            to={'/'}
                                                            className="d-block mt-3 text-muted">
                                                            Remember your password?
                                                        </Link>
                                                    </form>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default PasswordRecovery;
