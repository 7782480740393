import { combineReducers } from 'redux'
import sidebarReducer from './reducer'

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
