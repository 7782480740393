import { SET_TOGGLE_SIDEBAR } from '../actions/constants'

const initialState = {
  toggleSidebar: true,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const state = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TOGGLE_SIDEBAR: {
      return {
        ...state,
        toggleSidebar: action.payload,
      }
    }
    default:
      return state
  }
}

export default state