import React, { useEffect, useState } from 'react'
import Container from '../../components/Container'
import Table from '../../components/Table'
import Tabs from '../../components/Tabs'
import { getAllCompanies, getDeletedCompanies, getNewTodayCompanies } from '../../api/Api'

interface Addresses {
    city: string
    country: string
    street: string
    zip_code: string
}


interface CompaniesType {
    addresses: Addresses[]
    comments: string
    commercial_services: [string]
    created_at: string
    email: string
    id: number
    miles_around: null
    name: string
    phones: [string]
    residential_services: [string]
    service_provided: [string]
}

interface CompaniesDataType {
    newCompanies: CompaniesType[]
    allCompanies: CompaniesType[]
    deletedCompanies: CompaniesType[]
}

const Companies: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [companiesData, setCompaniesData] = useState<CompaniesDataType>({
        newCompanies: [],
        allCompanies: [],
        deletedCompanies: [],
    })

    const columns = {
        id: {
            title: 'Id',
        },
        name: {
            title: 'Name',
        },
        phones: {
            title: 'Phones',
        },
        email: {
            title: 'Email',
        },
        created_at: {
            title: 'Created at',
        },
        comments: {
            title: 'comments',
        },
        residential_services: {
            title: 'residential services',
        },
        commercial_services: {
            title: 'commercial services',
        },
        miles_around: {
            title: 'Miles around',
        },
        service_provided: {
            title: 'Service provided',
        },
        addresses: {
            title: 'Addresses',
        },
    }
    const tabs = [
        {
            key: 'newCompanies',
            title: 'New Companies',
            content: <Table items={companiesData.newCompanies} columns={columns}/>
        },
        {
            key: 'allCompanies',
            title: 'All Companies',
            content: <Table items={companiesData.allCompanies} columns={columns}/>
        },
        {
            key: 'deletedCompanies',
            title: 'Deleted Companies',
            content: <Table items={companiesData.deletedCompanies} columns={columns}/>
        },
    ]

    const onClickTab = async (name: string) => {
        switch (name) {
            case 'newCompanies':
                setIsLoading(true)
                getNewTodayCompanies()
                    .then(({data}) => {
                        setCompaniesData(prevCompanies => ({
                            ...prevCompanies,
                            newCompanies: data.map((item: CompaniesType) => ({
                                id: item.id,
                                name: item.name,
                                phones: item.phones.map((phone: string, _, array) => array.length > 1 ? `${phone}\n` : phone),
                                email: item.email,
                                created_at: item.created_at,
                                comments: item.comments,
                                residential_services: item.residential_services.map((rs, _, array) => array.length > 1 ? `${rs}\n` : rs),
                                commercial_services: item.commercial_services.map((cs, _, array) => array.length > 1 ? `${cs}\n` : cs),
                                miles_around: item.miles_around,
                                service_provided: item.service_provided.map((sp, _, array) => array.length > 1 ? `${sp}\n` : sp),
                                addresses: item.addresses.map((address) => `${address.zip_code} ${address.street} ${address.city} ${address.country}/\n`),
                            }))
                        }))
                        setIsLoading(false)
                    })
                    .finally(() => setIsLoading(false))
                break

            case 'allCompanies':
                setIsLoading(true)
                getAllCompanies()
                    .then(({data}) => {
                        setCompaniesData(prevCompanies => ({
                            ...prevCompanies,
                            allCompanies: data.map((item: CompaniesType) => ({
                                id: item.id,
                                name: item.name,
                                phones: item.phones.map((phone: string, _, array) => array.length > 1 ? `${phone}\n` : phone),
                                email: item.email,
                                created_at: item.created_at,
                                comments: item.comments,
                                residential_services: item.residential_services.map((rs, _, array) => array.length > 1 ? `${rs}\n` : rs),
                                commercial_services: item.commercial_services.map((cs, _, array) => array.length > 1 ? `${cs}\n` : cs),
                                miles_around: item.miles_around,
                                service_provided: item.service_provided.map((sp, _, array) => array.length > 1 ? `${sp}\n` : sp),
                                addresses: item.addresses.map((address) => `${address.zip_code} ${address.street} ${address.city} ${address.country}/\n`),
                            }))
                        }))
                        setIsLoading(false)
                    })
                    .finally(() => setIsLoading(false))
                break

            case 'deletedCompanies':
                setIsLoading(true)
                getDeletedCompanies()
                    .then(({data}) => {
                        setCompaniesData(prevCompanies => ({
                            ...prevCompanies,
                            deletedCompanies: data.map((item: CompaniesType) => ({
                                id: item.id,
                                name: item.name,
                                phones: item.phones.map((phone: string, _, array) => array.length > 1 ? `${phone}\n` : phone),
                                email: item.email,
                                created_at: item.created_at,
                                comments: item.comments,
                                residential_services: item.residential_services.map((rs, _, array) => array.length > 1 ? `${rs}\n` : rs),
                                commercial_services: item.commercial_services.map((cs, _, array) => array.length > 1 ? `${cs}\n` : cs),
                                miles_around: item.miles_around,
                                service_provided: item.service_provided.map((sp, _, array) => array.length > 1 ? `${sp}\n` : sp),
                                addresses: item.addresses.map((address) => `${address.zip_code} ${address.street} ${address.city} ${address.country}/\n`),
                            }))
                        }))
                        setIsLoading(false)
                    })
                    .finally(() => setIsLoading(false))
                break

            default:
                break
        }
    }

    useEffect(() => {
        (async function () {
            const resp: { status: string; data: CompaniesType } = await getNewTodayCompanies()
            console.log('resp', resp)
            setCompaniesData(prev => ({...prev, newCompanies: [resp.data]}))
        })()
    }, [])

    return (
        <Container title="Companies">
            <Tabs
                defaultActiveKey="newCompanies"
                tabs={tabs}
                onClickTab={onClickTab}
                isLoading={isLoading}
            />
        </Container>
    )
}

export default Companies
