import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { sendAuth } from '../../api/Api'
import logo from '../../icon/logo.svg'
import styles from './styles.module.scss'
import { useFormik } from "formik";

const Login: React.FC = () => {
    const [error, setError] = useState('')
    const [remember, setRemember] = useState(false)
    const navigate = useNavigate()

    const handleLogin = (email: string, password: string) => {
        const data = {
            email: email,
            password: password,
        }

        sendAuth(data)
            .then(res => {
                if (res.status === 'error') {
                    const error = res.error?.password[0] || res.error?.email[0] || res.message
                    setError(error)
                    return
                }
                const storage = remember ? localStorage : sessionStorage
                const noStorage = !remember ? localStorage : sessionStorage
                storage.setItem('token', res.data.access_token)
                noStorage.removeItem('token')
                navigate('dashboard')
            })
            .catch((error) => {
                console.log('e: ', error)
            })
    }

    const formik = useFormik({
        initialValues: {
            userPassword: '',
            userEmail: '',
        },
        onSubmit: ({userEmail, userPassword}) => {
            handleLogin(userEmail, userPassword)
        },
    })


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.pageWrapper}>
                <div className={styles.pageContent}>
                    <Row className={styles.authPage}>
                        <Col md="8" xl="6" className="mx-auto">
                            <div className={styles.card}>
                                <Row>
                                    <Col md="4" className="pe-md-0">
                                        <div className={styles.authSideWrapper}/>
                                    </Col>
                                    <Col md="8" className="ps-md-0">
                                        <div className="auth-form-wrapper px-4 py-5">
                                            <img src={logo} alt="logo" width={315}/>
                                            <h5 className="text-muted fw-normal mb-4">
                                                Welcome back! Log in to your account.
                                            </h5>
                                            <form className="forms-sample" onSubmit={formik.handleSubmit}
                                                  onChange={() => setError('')}>
                                                <div className="mb-3">
                                                    <label htmlFor="userEmail" className="form-label">
                                                        Email address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="userEmail"
                                                        name='userEmail'
                                                        placeholder="Email"
                                                        value={formik.values.userEmail}
                                                        onChange={formik.handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="userPassword" className="form-label">
                                                        Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="userPassword"
                                                        name='userPassword'
                                                        autoComplete="current-password"
                                                        placeholder="Password"
                                                        value={formik.values.userPassword}
                                                        onChange={formik.handleChange}
                                                        required
                                                    />
                                                    <Link
                                                        style={{width: 'max-content'}}
                                                        to={'/password-recovery'}
                                                        className="d-block ms-auto mt-3 text-muted">
                                                        Forgot your password?
                                                    </Link>
                                                </div>

                                                <div className="form-check mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="authCheck"
                                                        onChange={() => setRemember(prevRemember => !prevRemember)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="authCheck">
                                                        Remember me
                                                    </label>
                                                </div>
                                                {error ? <p className='text-danger'>{error}</p> : null}
                                                <button
                                                    type='submit'
                                                    disabled={!!error}
                                                    className="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                                                    Login
                                                </button>
                                                <Link
                                                    to={'/register'}
                                                    className="d-block mt-3 text-muted">
                                                    Not a user? Sign up
                                                </Link>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Login
