import React from 'react'
import Container from '../../components/Container'
import Table from '../../components/Table'
import Tabs from '../../components/Tabs'

const reviewsData = {
  newReviews: [
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
  ],
  allReviews: [
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
  ],
  deletedReviews: [
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
    {
      date: new Date().toDateString(),
      company: 'Google',
      status: 'edited',
      author: 'Anton',
      authorEmail: 'anton@gmail.com',
      authorPhone: '2 333 444 55 66',
      text: 'Some text...',
      link: 'https://google.com',
    },
  ],
}

const arrayToTableData = (array: any[]) =>
  array.map(item => ({
    ...item,
    link: <a href={item.link}>More{'>>'}</a>,
  }))

const Reviews: React.FC = () => {
  const columns = {
    date: {
      title: 'Date',
    },
    company: {
      title: 'Company',
    },
    status: {
      title: 'Status',
    },
    author: {
      title: 'Author',
    },
    authorEmail: {
      title: 'Author email',
    },
    authorPhone: {
      title: 'Author phone',
    },
    text: {
      title: 'Text',
    },
    link: {
      title: 'More',
    },
  }

  const tabs = [
    {
      key: 'newReviews',
      title: 'New Reviews',
      content: (
        <Table
          items={arrayToTableData(reviewsData.newReviews)}
          columns={columns}
        />
      ),
    },
    {
      key: 'allReviews',
      title: 'All Reviews',
      content: (
        <Table
          items={arrayToTableData(reviewsData.allReviews)}
          columns={columns}
        />
      ),
    },
    {
      key: 'deletedReviews',
      title: 'Deleted Reviews',
      content: (
        <Table
          items={arrayToTableData(reviewsData.deletedReviews)}
          columns={columns}
        />
      ),
    },
  ]

  return (
    <Container title="Reviews">
      <Tabs defaultActiveKey="newReviews" tabs={tabs} onClickTab={() => false} />
    </Container>
  )
}

export default Reviews
