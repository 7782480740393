// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__ubW-a, .styles_containerMax__hAFw2 {
  position: absolute;
  top: 90px;
  padding: 15px 25px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
}

.styles_container__ubW-a {
  left: 265px;
  width: 80%;
}

.styles_containerMax__hAFw2 {
  left: 95px;
  width: 92%;
}

.styles_container__ubW-a,
.styles_containerMax__hAFw2 {
  overflow-x: auto;
}
.styles_container__ubW-a::-webkit-scrollbar,
.styles_containerMax__hAFw2::-webkit-scrollbar {
  background: #7987A1;
  height: 3px;
}

.styles_title__e-WWs {
  font-size: 19px;
}`, "",{"version":3,"sources":["webpack://./src/components/Container/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,+CAAA;EACA,uDAAA;EACA,oDAAA;EACA,mDAAA;AACF;;AAEA;EACE,WAAA;EACA,UAAA;AACF;;AAGA;EACE,UAAA;EACA,UAAA;AAAF;;AAGA;;EAEE,gBAAA;AAAF;AAEE;;EACE,mBAAA;EACA,WAAA;AACJ;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".container, .containerMax {\n  position: absolute;\n  top: 90px;\n  padding: 15px 25px;\n  border-radius: 8px;\n  background: #fff;\n  box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);\n  -webkit-box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);\n  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);\n  -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);\n}\n\n.container {\n  left: 265px;\n  width: 80%;\n}\n\n\n.containerMax {\n  left: 95px;\n  width: 92%;\n}\n\n.container,\n.containerMax {\n  overflow-x: auto;\n\n  &::-webkit-scrollbar {\n    background: #7987A1;\n    height: 3px;\n  }\n}\n\n.title {\n  font-size: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__ubW-a`,
	"containerMax": `styles_containerMax__hAFw2`,
	"title": `styles_title__e-WWs`
};
export default ___CSS_LOADER_EXPORT___;
