import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { store } from './store/store'
import './App.scss'

import Login from './pages/login'
import Dashboard from './pages/dashboard'
import Leads from './pages/Leads'
import Companies from './pages/Companies'
import Users from './pages/Users'
import Connect from './pages/Connect'
import Reviews from './pages/Reviews'
import PrivateRoute, { LogoutRoute } from './routes/PrivedRoute'
import Register from './pages/Register'
import Chat from './pages/Сhat'
import TestCompanies from "./pages/TestCompany";
import PasswordRecovery from "./pages/PasswordRecovery";

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<LogoutRoute component={Login}/>}/>
                    <Route
                        path="/register"
                        element={<LogoutRoute component={Register}/>}
                    />
                    <Route
                        path="/password-recovery"
                        element={<LogoutRoute component={PasswordRecovery}/>}
                    /> <Route
                    path="/change-password*"
                    element={<LogoutRoute component={PasswordRecovery}/>}
                />
                    <Route
                        path="/dashboard"
                        element={<PrivateRoute component={Dashboard}/>}
                    />
                    <Route path={'/chat/*'} element={<PrivateRoute component={Chat}/>}/>
                    <Route path="/leads" element={<PrivateRoute component={Leads}/>}/>
                    <Route
                        path="/companies"
                        element={<PrivateRoute component={Companies}/>}
                    />
                    <Route
                        path="/test-company"
                        element={<PrivateRoute component={TestCompanies}/>}
                    />
                    <Route path="/users" element={<PrivateRoute component={Users}/>}/>
                    <Route
                        path="/connect"
                        element={<PrivateRoute component={Connect}/>}
                    />
                    <Route
                        path="/reviews"
                        element={<PrivateRoute component={Reviews}/>}
                    />
                    <Route path="*" element={<h1>404</h1>}/>
                </Routes>
            </Router>
        </Provider>
    )
}

export default App
