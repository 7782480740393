import React, { useEffect, useState } from 'react'
import { getChatMessage, markMessages } from '../../api/Api'
import styles from './styles.module.scss'
import { MessagesType } from './ChatUsersBox'
import { useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useGlobalState } from '../../store/GlobalState'
import ChatMessages, { ChatMessagesType } from './ChatMessages'
import { SendMessage } from './SendMessage'
import dayjs from 'dayjs'

export interface ChatType
  extends Pick<MessagesType, 'chat_id'>,
    ChatMessagesType {}

const ChatBoxMessages: React.FC = () => {
  const [lastMessage, setLastMessage] = useGlobalState('last_message')
  const [marksMessages, setMarksMessages] = useGlobalState('marksMessages')
  const [user, setUserData] = useGlobalState('user')
  const [chats, setChats] = useState<ChatType | null>({
    chat_id: 0,
    interlocutor: { id: 0, name: '', photo: '' },
    messages: [],
  })

  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState({
    fetch: false,
    countEnd: false,
  })
  const [offset, setOffset] = useState(0)
  const params = useParams()

  const markMsg = async () => {
    if (chats?.chat_id) {
      await markMessages(Number(params.id?.slice(3)))
      setLastMessage(prev =>
        prev.chatId === Number(params.id?.slice(3))
          ? {
              ...prev,
              numberOfUnreadMessages: 0,
            }
          : prev,
      )
    }
  }

  useEffect(() => {
    setChats(prev => ({
      ...prev,
      messages: [],

      chat_id: 0,
      interlocutor: { id: 0, name: '', photo: '' },
    }))
    setOffset(0)
    setFetching(prev => ({ ...prev, fetch: true, countEnd: false }))
    markMsg()
  }, [params.id])

  useEffect(() => {
    if (
      lastMessage.chatId &&
      lastMessage.chatId === Number(params.id?.slice(3))
    ) {
      setChats(
        prev =>
          prev && {
            ...prev,
            chat_id: lastMessage.chatId,
            messages: [
              {
                message_id: lastMessage.messageId,
                message: lastMessage.message,
                user_id: lastMessage.userId,
                created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
              },
              ...prev.messages,
            ],
          },
      )
    }
  }, [lastMessage])

  useEffect(() => {
    const fetchData = async () => {
      const countMessages = 50
      setLoading(true)
      const resp = await getChatMessage(
        Number(params.id?.slice(3)),
        `offset=${offset}&limit=${countMessages}`,
      )

      if (!resp.messages) {
        setFetching(prev => ({ ...prev, fetch: false, countEnd: true }))
        setLoading(false)
        return
      }

      const { interlocutor, messages, chat_id } = resp

      const sortMessages = messages.sort(
        (m1: { message_id: number }, m2: { message_id: number }) =>
          m1.message_id - m2.message_id,
      )

      const newMessage = chats
        ? [...chats.messages, ...sortMessages]
        : sortMessages

      setChats(prev => ({
        ...prev,
        messages: newMessage,
        interlocutor,
        chat_id: chat_id,
      }))
      setOffset(offset + countMessages)

      setLoading(false)
      setFetching(prev => ({ ...prev, fetch: false }))
    }

    if (fetching.fetch && !fetching.countEnd) fetchData()
  }, [fetching])

  // Add user id HARDCORE
  useEffect(() => {
    if (chats && !user.userId) {
      const idUser = chats.messages.find(
        message => message.user_id !== chats.interlocutor.id,
      )
      if (idUser) setUserData(prev => ({ ...prev, userId: idUser.user_id }))
    }
  }, [chats])

  return (
    <div className="d-flex col-7 col-lg-8 px-0 flex-column justify-content-between">
      <div
        id="scrollableDiv"
        className={`px-4 py-5  ${styles.chatBox} bg-white`}>
        {loading && (
          <div className="m-auto spinner-border text-primary" role="status" />
        )}
        <InfiniteScroll
          dataLength={chats?.messages.length ?? 0}
          next={() => setFetching(prev => ({ ...prev, fetch: true }))}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={true}
          loader={<></>}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.5}>
          {chats && (
            <ChatMessages
              messages={chats.messages}
              interlocutor={chats.interlocutor}
            />
          )}
        </InfiniteScroll>
      </div>

      {/*Send messages*/}
      {!loading && <SendMessage chat_id={Number(params.id?.slice(3))} />}
    </div>
  )
}
export default React.memo(ChatBoxMessages)
