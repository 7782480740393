// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/banner-admin.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pageWrapper__NUpKQ {
  width: 100%;
  margin-left: 0;
  background-color: rgb(46, 144, 203);
  height: 100vh;
  overflow: hidden;
}

.styles_pageContent__N8QVq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 25px;
  margin-top: 0;
}

.styles_card__f8mvO {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #f2f4f9;
  border-radius: 0.25rem;
  margin: auto;
  background-color: rgb(213, 235, 222);
  border: 2px solid rgb(11, 93, 144);
  box-shadow: rgb(11, 93, 144) 3px 0px 10px 0px;
  width: 592px;
  margin: 0px auto;
}

.styles_authPage__Rf3pj {
  width: 100%;
  margin: 0;
}

.styles_authSideWrapper__DBt5v {
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}

.styles_nobleUiLogo__9xcwB {
  font-weight: 700;
  font-size: 25px;
  color: #000865;
  display: block;
  margin-bottom: 16px;
  text-decoration: none;
}
.styles_nobleUiLogo__9xcwB span {
  color: #6571ff;
  font-weight: 300;
}
.styles_nobleUiLogo__9xcwB:hover {
  color: #000865;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,mCAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,qBAAA;EACA,2BAAA;EACA,yBAAA;EACA,sBAAA;EACA,YAAA;EACA,oCAAA;EACA,kCAAA;EACA,6CAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,mBAAA;EACA,qBAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".pageWrapper {\n  width: 100%;\n  margin-left: 0;\n  background-color: rgb(46, 144, 203);\n  height: 100vh;\n  overflow: hidden;\n}\n\n.pageContent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n  padding: 25px;\n  margin-top: 0;\n}\n\n.card {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-width: 0;\n  word-wrap: break-word;\n  background-clip: border-box;\n  border: 1px solid #f2f4f9;\n  border-radius: 0.25rem;\n  margin: auto;\n  background-color: rgb(213, 235, 222);\n  border: 2px solid rgb(11, 93, 144);\n  box-shadow: rgb(11 93 144) 3px 0px 10px 0px;\n  width: 592px;\n  margin: 0px auto;\n}\n\n.authPage {\n  width: 100%;\n  margin: 0;\n}\n\n.authSideWrapper {\n  width: 100%;\n  height: 100%;\n  background-image: url(../../img/banner-admin.jpeg);\n  background-size: cover;\n}\n\n.nobleUiLogo {\n  font-weight: 700;\n  font-size: 25px;\n  color: #000865;\n  display: block;\n  margin-bottom: 16px;\n  text-decoration: none;\n  span {\n    color: #6571ff;\n    font-weight: 300;\n  }\n  &:hover {\n    color: #000865;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": `styles_pageWrapper__NUpKQ`,
	"pageContent": `styles_pageContent__N8QVq`,
	"card": `styles_card__f8mvO`,
	"authPage": `styles_authPage__Rf3pj`,
	"authSideWrapper": `styles_authSideWrapper__DBt5v`,
	"nobleUiLogo": `styles_nobleUiLogo__9xcwB`
};
export default ___CSS_LOADER_EXPORT___;
