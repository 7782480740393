import React from 'react'
import { Navigate } from 'react-router-dom'
import Header from '../components/header'

interface Props {
    component: React.FC
}

const PrivateRoute: React.FC<Props> = ({component: RouteComponent}) => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token')

    if (token) {
        return (
            <>
                <Header/>
                <RouteComponent />
            </>
        )
    }

    return <Navigate to="/"/>
}

export const LogoutRoute: React.FC<Props> = ({component: RouteComponent}) => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token')

    if (!token) {
        return (
            <>
                <RouteComponent />
            </>
        )
    }

    return <Navigate to="/dashboard"/>
}

export default PrivateRoute
