// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_table__0\\+Z02 {
  border-collapse: inherit;
}

.styles_th__pVWZM {
  color: #7987a1;
  text-transform: uppercase;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.styles_td__gSirC {
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}

.styles_links__knhpX {
  color: var(--bs-table-striped-color);
  text-decoration: none;
}

.styles_tr__cIwst {
  border-bottom: 1px solid #e9ecef;
}

.styles_actions__xtwSg {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3px;
}
.styles_actions__xtwSg * {
  font-size: 14px;
}
.styles_actions__xtwSg .styles_left__pS7zi, .styles_actions__xtwSg .styles_right__DJG72 {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.styles_actions__xtwSg .styles_search__uECUG {
  max-width: 200px;
  display: flex;
  align-items: center;
}
.styles_actions__xtwSg .styles_search__uECUG .styles_input__awu0z {
  padding-right: 20px;
}
.styles_actions__xtwSg .styles_search__uECUG .styles_icon__D-yJd {
  margin-left: -20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,oCAAA;EACA,qBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EAEA,YAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;EACA,oBAAA;AADJ;AAIE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAII;EACE,mBAAA;AAFN;AAKI;EACE,kBAAA;AAHN","sourcesContent":[".table {\n  border-collapse: inherit;\n}\n\n.th {\n  color: #7987a1;\n  text-transform: uppercase;\n  font-size: 12px;\n  white-space: nowrap;\n  text-align: center;\n  user-select: none;\n  cursor: pointer;\n}\n\n.td {\n  font-size: 14px;\n  vertical-align: middle;\n  text-align: center;\n}\n\n.links {\n  color: var(--bs-table-striped-color);\n  text-decoration: none;\n}\n\n.tr {\n  border-bottom: 1px solid #e9ecef;\n}\n\n.actions {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  // border-bottom: 1px solid #dee2e6;\n  padding: 3px;\n\n  * {\n    font-size: 14px;\n  }\n\n  .left, .right {\n    display: flex;\n    gap: 10px;\n    justify-content: end\n  }\n\n  .search {\n    max-width: 200px;\n    display: flex;\n    align-items: center;\n\n    .input {\n      padding-right: 20px;\n    }\n\n    .icon {\n      margin-left: -20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `styles_table__0+Z02`,
	"th": `styles_th__pVWZM`,
	"td": `styles_td__gSirC`,
	"links": `styles_links__knhpX`,
	"tr": `styles_tr__cIwst`,
	"actions": `styles_actions__xtwSg`,
	"left": `styles_left__pS7zi`,
	"right": `styles_right__DJG72`,
	"search": `styles_search__uECUG`,
	"input": `styles_input__awu0z`,
	"icon": `styles_icon__D-yJd`
};
export default ___CSS_LOADER_EXPORT___;
