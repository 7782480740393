import React from "react";
import { Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom";

import NoticeIcon from '../../../../icon/notice.svg'
import avatar from '../../../../img/face.jpg'

import styles from './styles.module.scss'

const Notice: React.FC = () => {

    return (
        <Dropdown autoClose="outside">
            <Dropdown.Toggle className="p-0 mx-1" variant="white" id="dropdown-basic">
                <div className={styles.noticeWrap}>
                    <img src={NoticeIcon} alt="Notice" width={20} height={20}/>
                    <div className={styles.indicator}>
                        <div className={styles.circle}></div>
                    </div>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdownNotice} align='end'>
                <div className="px-3 pb-2 d-flex justify-content-between border-bottom">
                    <p className={styles.messages}>6 New Notifications</p>
                    <Link to={'/'} className={styles.noticeClear}>Clear all</Link>
                </div>
                <Dropdown.Item eventKey="1" className='d-flex align-items-center pt-2 pb-2'>
                    <div className={styles.noticeDropdownWrap}>
                        <img src={avatar} alt="avatar"/>
                        <div className={styles.noticeText}>
                            <div className="me-4">
                                <p className={styles.noticeName}>Leonardo Payne</p>
                                <p className={styles.noticeStatus}>Project status</p>
                            </div>
                        </div>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Notice