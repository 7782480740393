import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { RootState } from '../../../store/rootReducer'
import { setToggleSidebar as _setToggleSidebar } from '../../../actions/actions'
import NavBar from '../Navbar'

import logo from '../../../icon/logo.svg'
import boxIcon from '../../../icon/box.svg'
import leadIcon from '../../../icon/lead.svg'
import userIcon from '../../../icon/user.svg'
import companiesIcon from '../../../icon/companies.svg'
import chatIcon from '../../../icon/chat.svg'
import styles from './styles.module.scss'

type Props = {
    readonly toggleSidebar: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setToggleSidebar: (val: any) => void
}

const Sidebar: React.FC<Props> = ({toggleSidebar, setToggleSidebar}) => {
    const handleClick = () => {
        setToggleSidebar(!toggleSidebar)
    }

    return (
        <div className={cn(styles.header, !toggleSidebar && styles.headerMin)}>
            <nav className={styles.navbar}>
                <NavBar/>
            </nav>
            <nav className={cn(styles.sidebar, !toggleSidebar && styles.sidebarMin)}>
                <div className={styles.sidebarHeader}>
                    <Link to={'/dashboard'} className={styles.sidebarBrand}>
                        <img
                            src={logo}
                            alt="logo"
                            width={120}
                            className={styles.linkIcon}
                        />
                    </Link>
                    <div className={styles.sidebarToggler} onClick={handleClick}>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
                <div className={styles.sidebarBody}>
                    <ul className={styles.nav}>
                        <li className={`${styles.navItem} ${styles.navCategory}`}>Main</li>
                        <Link
                            to="/chat"
                            className={`${styles.navLink} ${styles.active}`}>
                            <img src={chatIcon} width={16} height={16} alt="box"/>
                            <span className={styles.linkTitle}>Chat</span>
                        </Link>
                        <Link
                            to="/dashboard"
                            className={`${styles.navLink} ${styles.active}`}>
                            <img src={boxIcon} width={16} height={16} alt="box"/>
                            <span className={styles.linkTitle}>Dashboard</span>
                        </Link>
                        <Link to="/leads" className={`${styles.navLink} ${styles.active}`}>
                            <img src={leadIcon} width={16} height={16} alt="lead"/>
                            <span className={styles.linkTitle}>Leads</span>
                        </Link>
                        <Link
                            to="/companies"
                            className={`${styles.navLink} ${styles.active}`}>
                            <img src={companiesIcon} width={16} height={16} alt="companies"/>
                            <span className={styles.linkTitle}>Companies</span>
                        </Link>
                        <Link
                            to="/test-company"
                            className={`${styles.navLink} ${styles.active}`}>
                            <img src={companiesIcon} width={16} height={16} alt="test company"/>
                            <span className={styles.linkTitle}>Test company</span>
                        </Link>
                        <Link to="/users" className={`${styles.navLink} ${styles.active}`}>
                            <img src={userIcon} width={16} height={16} alt="users"/>
                            <span className={styles.linkTitle}>Users</span>
                        </Link>
                        <Link
                            to="/connect"
                            className={`${styles.navLink} ${styles.active}`}>
                            <img src={boxIcon} width={16} height={16} alt="box"/>
                            <span className={styles.linkTitle}>Connect</span>
                        </Link>
                        <Link
                            to="/reviews"
                            className={`${styles.navLink} ${styles.active}`}>
                            <img src={boxIcon} width={16} height={16} alt="box"/>
                            <span className={styles.linkTitle}>Reviews</span>
                        </Link>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    toggleSidebar: state.sidebar.toggleSidebar,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setToggleSidebar: (val: any) => dispatch(_setToggleSidebar(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
