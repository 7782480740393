import axios from 'axios'
import axiosInstance from './axiosInstance'
import axiosMedia from './axiosInstance'
import { URL } from '../constants'
import { Interlocutor } from '../pages/Сhat/ChatUsersBox'

export interface ChatsAPIType {
    chat_id: number
    interlocutor: Interlocutor
    last_message: {
        message: string
        created_at: string
    }
    number_of_unread_messages: number
}

// leads page
export const getLeadsInWork = () =>
    axiosInstance.get('/dashboard/companies/leads/in-work').then(res => res.data)

export const getLeadsAll = () =>
    axiosInstance.get('/dashboard/companies/leads/all').then(res => res.data)

export const getLeadsNewToday = () =>
    axiosInstance.get('/dashboard/companies/leads/new-today').then(res => res.data)

export const getLeadsAvailable = () =>
    axiosInstance
        .get('/dashboard/companies/leads/available')
        .then(res => res.data)

export const getLeadsCompleted = () =>
    axiosInstance
        .get('/dashboard/companies/leads/completed')
        .then(res => res.data)


// companies
export const getAllCompanies = () =>
    axiosInstance.get('/dashboard/companies/all').then(res => res.data)

export const getNewTodayCompanies = () =>
    axiosInstance.get('/dashboard/companies/new-today').then(res => res.data)

export const getDeletedCompanies = () =>
    axiosInstance.get('/dashboard/companies/deleted').then(res => res.data)

export const postTestCompany = (data: FormData) => axiosInstance.post('/dashboard/companies/demo-companies/import', data)
    .then(res => res.data).catch((data) => data.response)

// Auth
export const sendAuth = (data: { email: string; password: string }) =>
    axios.post(URL + '/dashboard/auth/login', data).catch(
        error => error.response.data
    )

export const refreshToken = () =>
    axiosInstance.post('/dashboard/auth/refresh').then(res => res.data)

export const authLogout = () =>
    axiosInstance.post('/dashboard/auth/logout').then(res => res.data)

export const sendSignup = (data: {
    name: string
    email: string
    password: string
    /* icon: string, */ rules: boolean
}) => axios.post(URL + '/app/clients/sign-up', data)

export const sendMedia = (data: FormData) => axiosMedia.post('/medias', data)

//Reset password
export const forgotPassword = (data: {
    email: string
}): Promise<{ error: { email: Array<string> }, status: 'error' | 'success', message: string }> =>
    axios.post(URL + '/dashboard/forgot-password', data).then(res => res.data).catch((error) => error.response.data)

export const getResetPassword = (
    token: string
) => axios.get(URL + `/dashboard/reset-password/${token}`).then(res => res.data).catch((error) => error.response.data)

export const resetPassword = (token: string, data: {
    new_password: string,
    repeat_password: string
}) => axios.post(URL + `/dashboard/reset-password/${token}`, data).then(res => res.data).catch((error) => error.response.data)


// Chat
export const getChats = () => axiosInstance.get('/dashboard/chats')

export const getChatMessage = (chat_id: number, query: string): Promise<any> =>
    axiosInstance
        .get(`/dashboard/chats/${chat_id}?${query}`)
        .then(resp => resp.data.data)

export const sendMessages = ({
                                 chat_id,
                                 message,
                             }: {
    chat_id: number
    message: string
}) =>
    axiosInstance.post('/dashboard/chats/send-message', {
        chat_id,
        message,
    })

export const sendInitMessage = (data: {
    recipient_id: number
    message: string
}) =>
    axiosInstance
        .post('/dashboard/chats/send-init-message', data)
        .catch(error =>
            error.response.status === 500 ? error.response.data : error,
        )

export const markMessages = (chat_id: number) =>
    axiosInstance.post(`/dashboard/chats/mark-messages/${chat_id}`, {chat_id})

// Client
export const getSearch = () => axiosInstance.get('/dashboard/clients/search')

// export const getLeads = () => {
//   console.log();
//   return axios.get('/dashboard/companies/leads/available')
// }

// export const sendPhone = (data: { phone: string }) =>
//   axios.post('/auth/login-phone-start', data)

// export const getCommunitiesCategory = (id: string, params?: string) =>
//   axios.get(`/communities/${id}/categories?${params}`)

// Company
export const getCompany = () =>
    axiosInstance.get('/dashboard/companies/dashboard').then(resp => resp.data)

// export const getCompanyLeadsAvailable = () =>
//   axiosInstance.get('/dashboard/companies/leads/available')
