// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_messages__2yvvX {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.styles_noticeClear__UBfWd {
  --bs-text-opacity: 1;
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.styles_noticeClear__UBfWd:hover {
  color: #6c757d;
}

.styles_noticeWrap__tcLJ6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_avatar__vO-hy {
  border-radius: 50%;
}

.styles_noticeText__0bpDq {
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-grow: 1;
}

.styles_noticeName__QFBzy {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.styles_noticeStatus__GN9zJ {
  font-size: 12px;
  font-weight: 500;
  color: #7987a1;
  margin-bottom: 0;
  white-space: nowrap;
}

.styles_time__h5cS5 {
  font-size: 12px;
  font-weight: 500;
  color: #7987a1;
  margin-bottom: 0;
  white-space: nowrap;
}

.styles_profilePopup__fykyo {
  width: 230px;
}

.styles_noticeWrapAvatar__VAlXe {
  border-radius: 50%;
}

.styles_logOut__jfgLJ {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px;
}
.styles_logOut__jfgLJ:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Navbar/Profile/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,oBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AACF;AAAE;EACE,cAAA;AAEJ;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;AACF;AAAE;EACE,WAAA;EACA,yBAAA;EACA,qBAAA;AAEJ","sourcesContent":[".messages {\n  font-size: 14px;\n  margin-bottom: 0;\n  font-weight: 500;\n}\n\n.noticeClear {\n  --bs-text-opacity: 1;\n  color: #6c757d;\n  font-size: 14px;\n  font-weight: 500;\n  text-decoration: none;\n  &:hover {\n    color: #6c757d;\n  }\n}\n\n.noticeWrap {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.avatar {\n  border-radius: 50%;\n}\n\n.noticeText {\n  display: flex;\n  text-align: center;\n  justify-content: space-between;\n  flex-grow: 1;\n}\n\n.noticeName {\n  margin-bottom: 0;\n  font-size: 13px;\n  font-weight: 500;\n  white-space: nowrap;\n}\n\n.noticeStatus {\n  font-size: 12px;\n  font-weight: 500;\n  color: #7987a1;\n  margin-bottom: 0;\n  white-space: nowrap;\n}\n\n.time {\n  font-size: 12px;\n  font-weight: 500;\n  color: #7987a1;\n  margin-bottom: 0;\n  white-space: nowrap;\n}\n\n.profilePopup {\n  width: 230px;\n}\n\n.noticeWrapAvatar {\n  border-radius: 50%;\n}\n\n.logOut {\n  color: #fff;\n  background-color: #0b5ed7;\n  border-color: #0a58ca;\n  text-decoration: none;\n  border-radius: 5px;\n  padding: 5px;\n  &:hover {\n    color: #fff;\n    background-color: #0b5ed7;\n    border-color: #0a58ca;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": `styles_messages__2yvvX`,
	"noticeClear": `styles_noticeClear__UBfWd`,
	"noticeWrap": `styles_noticeWrap__tcLJ6`,
	"avatar": `styles_avatar__vO-hy`,
	"noticeText": `styles_noticeText__0bpDq`,
	"noticeName": `styles_noticeName__QFBzy`,
	"noticeStatus": `styles_noticeStatus__GN9zJ`,
	"time": `styles_time__h5cS5`,
	"profilePopup": `styles_profilePopup__fykyo`,
	"noticeWrapAvatar": `styles_noticeWrapAvatar__VAlXe`,
	"logOut": `styles_logOut__jfgLJ`
};
export default ___CSS_LOADER_EXPORT___;
