import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import EmailIcon from '../../../../icon/email.svg'
import avatar from '../../../../img/face.jpg'

import styles from './styles.module.scss'

const Email: React.FC = () => {
    return (
        <Dropdown autoClose="outside">
            <Dropdown.Toggle className="p-0 mx-1" variant="white" id="dropdown-basic">
                <img src={EmailIcon} alt="Email" width={20} height={20}/>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
                <div className="px-3 pb-2 d-flex justify-content-between border-bottom">
                    <p className={styles.messages}>9 New Messages</p>
                    <Link to={'/'} className={styles.emailClear}>
                        Clear all
                    </Link>
                </div>
                <Dropdown.Item
                    eventKey="1"
                    className="d-flex align-items-center pt-2 pb-2">
                    <div className={styles.emailWrap}>
                        <img src={avatar} alt="avatar"/>
                        <div className={styles.emailText}>
                            <div className="me-4">
                                <p className={styles.emailName}>Leonardo Payne</p>
                                <p className={styles.emailStatus}>Project status</p>
                            </div>
                            <p className={styles.time}>2 min ago</p>
                        </div>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Email
