import React, { KeyboardEventHandler, useEffect, useState } from 'react'
import { sendMessages } from '../../api/Api'
import styles from './styles.module.scss'
import { MessagesType } from './ChatUsersBox'
import sendIcon from '../../icon/send.svg'
import { useParams } from 'react-router'
import { MessageType } from './ChatMessages'

interface SendMessageType extends Pick<MessagesType, 'chat_id'> {
  onClicked?: (sendMessage: MessageType) => void
}

export const SendMessage: React.FC<SendMessageType> = ({ chat_id }) => {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const params = useParams()
  const inputRef = React.useRef<HTMLInputElement>(null)

  const sendMessage = async () => {
    setLoading(true)

    if (message) {
      await sendMessages({
        chat_id,
        message,
      })

      setMessage('')
    } else {
      setError('Write text!!!')
    }

    setLoading(false)
    inputRef.current?.focus()
  }

  const send = async (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    await sendMessage()
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter') {
      send(event)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
    setError('')
  }

  useEffect(() => {
    setMessage('')
  }, [params.id])

  return (
    <form action="#" className={`bg-light ${styles.formSendMessage}`}>
      <div className="input-group">
        <input
          value={message}
          ref={inputRef}
          autoFocus={true}
          onChange={event => handleChange(event)}
          onKeyDown={onKeyDown}
          disabled={loading}
          type="text"
          placeholder="Type a message"
          className={`form-control h-100 rounded-0 border-0 py-2 bg-light
           ${styles.input} ${error ? 'is-invalid' : ''}`}
        />
        <div className="input-group-append d-flex p-1">
          {loading ? (
            <div
              className="fs-1 w-25 h-25 m-auto spinner-border text-primary"
              role="status"
            />
          ) : (
            <button
              type="submit"
              className={`btn btn-link d-flex align-items-center ${styles.sendBtn}`}
              onClick={event => send(event)}>
              <img src={sendIcon} width={16} height={16} alt="send" />
            </button>
          )}
        </div>
      </div>
    </form>
  )
}
