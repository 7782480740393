import React, { useState } from 'react';
import { useFormik } from "formik";
import { resetPassword } from "../../api/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IProps {
    token: string
}

export const NewPassword: React.FC<IProps> = ({token}) => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const reset = async (new_password: string, repeat_password: string) => {
        const data = {
            new_password,
            repeat_password
        }
        setLoading(true)
        const res = await resetPassword(token, data)
        setLoading(false)

        if (res.error) {
            const {new_password, repeat_password, message} = res.error
            toast.error(message || 'Error!')
            if (new_password) {
                setError(new_password[0])
            }
            if (repeat_password) {
                setError(repeat_password[0])
            }
        }

        if (res.status === 'success') {
            toast.success(res.message || 'Success!')
            navigate('/')
        }
    }

    const formik = useFormik({
        initialValues: {
            userPassword: '',
            userPassword2: '',
        },
        onSubmit: ({userPassword, userPassword2}) => {
            reset(userPassword, userPassword2)
        },
    })
    return (
        <form className="forms-sample" onSubmit={formik.handleSubmit}
              onChange={() => setError('')}>
            <div className="mb-3">
                <label htmlFor="userPassword" className="form-label">
                    Password
                </label>
                <input
                    type="password"
                    className="form-control"
                    id="userPassword"
                    name='userPassword'
                    autoComplete="current-password"
                    placeholder="Password"
                    value={formik.values.userPassword}
                    onChange={formik.handleChange}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="userPassword" className="form-label">
                    Repeat password
                </label>
                <input
                    type="password"
                    className="form-control"
                    id="userPassword2"
                    name='userPassword2'
                    autoComplete="current-password"
                    placeholder="Password"
                    value={formik.values.userPassword2}
                    onChange={formik.handleChange}
                    required
                />
            </div>
            {error ? <p className='text-danger'>{error}</p> : null}
            <button
                type='submit'
                disabled={!!error || loading}
                className="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                Save
            </button>
        </form>
    );
};

