import React from 'react'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { RootState } from '../../store/rootReducer'
import { setToggleSidebar as _setToggleSidebar } from '../../actions/actions'

import styles from './styles.module.scss'

type Props = {
  readonly toggleSidebar: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setToggleSidebar: (val: any) => void
  children: React.ReactNode
  title: string
}

const Container: React.FC<Props> = ({ toggleSidebar, children, title }) => {
  return (
    <div
      className={
        toggleSidebar === true ? styles.container : styles.containerMax
      }>
      <h1 className={styles.title}>{title}</h1>
      {children}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  toggleSidebar: state.sidebar.toggleSidebar,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setToggleSidebar: (val: any) => dispatch(_setToggleSidebar(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
