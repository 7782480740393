// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_selectFile__34q37 {
  width: 50%;
  margin: 40px auto;
}
.styles_selectFile__34q37 input:focus,
.styles_selectFile__34q37 input:active {
  box-shadow: none;
}

.styles_testCompaniesTable__RzNZT {
  height: 75vh;
  overflow: auto;
}
.styles_testCompaniesTable__RzNZT::-webkit-scrollbar {
  opacity: 0.1;
  width: 5px;
  height: 5px;
  background: #f7f8fa;
}
.styles_testCompaniesTable__RzNZT::-webkit-scrollbar:hover {
  opacity: 1;
}
.styles_testCompaniesTable__RzNZT::-webkit-scrollbar-thumb {
  height: 9px;
  background-color: #7987A1;
  border-radius: 20px;
}
.styles_testCompaniesTable__RzNZT td {
  max-width: 350px;
}

.styles_messageError__BVLwv {
  display: flex;
  width: max-content;
  margin: 10px auto;
  color: rgba(239, 21, 21, 0.7215686275);
}`, "",{"version":3,"sources":["webpack://./src/pages/TestCompany/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;AACF;AACE;;EAEE,gBAAA;AACJ;;AAGA;EACE,YAAA;EACA,cAAA;AAAF;AAEE;EACE,YAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAEI;EACE,UAAA;AAAN;AAIE;EACE,WAAA;EACA,yBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;;AAOA;EACE,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,sCAAA;AAJF","sourcesContent":[".selectFile {\n  width: 50%;\n  margin: 40px auto;\n\n  input:focus,\n  input:active {\n    box-shadow: none;\n  }\n}\n\n.testCompaniesTable {\n  height: 75vh;\n  overflow: auto;\n\n  &::-webkit-scrollbar {\n    opacity: 0.1;\n    width: 5px;\n    height: 5px;\n    background: #f7f8fa;\n\n    &:hover {\n      opacity: 1;\n    }\n  }\n\n  &::-webkit-scrollbar-thumb {\n    height: 9px;\n    background-color: #7987A1;\n    border-radius: 20px;\n  }\n\n  td {\n    max-width: 350px;\n  }\n}\n\n.messageError {\n  display: flex;\n  width: max-content;\n  margin: 10px auto;\n  color: #ef1515b8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectFile": `styles_selectFile__34q37`,
	"testCompaniesTable": `styles_testCompaniesTable__RzNZT`,
	"messageError": `styles_messageError__BVLwv`
};
export default ___CSS_LOADER_EXPORT___;
